module.exports = {
  siteURL: "http://freewaysa.apolocrm.com",
  app: {
    apiKey: "AIzaSyBmLJSJ9DUzB691KcFIL0D-GxCSTfZ34Zg",
    authDomain: "freewaysa-awy.firebaseapp.com",
    projectId: "freewaysa-awy",
    storageBucket: "freewaysa-awy.appspot.com",
    messagingSenderId: "92308009221",
    appId: "1:92308009221:web:ebd2179f7ab665cb49876c"
  },
  tokens: ["bl5D2SJ/8r8iBQd6MO/8y8mOjqWNDTc1meXhtGtr10Su6/8M4GhocPuyJJS2z+jyULdBpcVtVcLANP380ZH+sw=="],
  gtm_id: "GTM-PJBCPF3",
  statitics_url: "https://datastudio.google.com/embed/reporting/7f694e98-735a-4f8d-89a1-9987a3acb45a/page/DVWQB",
  report_activities_url: "https://datastudio.google.com/embed/reporting/7c3ec8a7-55ee-4c9b-9337-aaaab11bd822/page/nYLqB",
  fcm_key: "BNRSSiZXn6WvNgKQAgv3Teny4zTcZADvR_IX1Vd4J6LpWr3Gyq12xqrWKmDOAmycNFcrnmJWsqtPchSegxmI1Ug",
  algolia: { id: "MSF1YRKSZK", key: "11ff95029fe0cdb25e409bfc5c121282", admin_key: "8939367140ad6a0d5393e1daf7936014" },
  whatsappServerURL: "",
  hideOriginForAdvisors: false,
  allowAllocationSameAgent: false,
  allowAllocationByPortion: false,
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Tipo de documento", key: "document_type", width: 20, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Tipo de documento", key: "document_type", width: 20, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "¿Acepto términos y condiciones?", key: "terms", type: "terms", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  contactFilters: {
    country: { id: "country", name: "País", primary: true },
    agent_id: { id: "agent_id", name: "Agente", primary: true, readonly_advisor: true, show_attr: "full_name" },
  },
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      placeholder: "Seleccione",
      items: ["DNI", "Carnet de extranjería"],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 6 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Ciudad",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      placeholder: "Distrito",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  contactFilters: {
    country: { id: "country", name: "País", primary: true },
    agent_id: { id: "agent_id", name: "Agente", primary: true, readonly_advisor: true, show_attr: "full_name" },
  },
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      placeholder: "Seleccione",
      items: ["DNI", "Carnet de extranjería"],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 6 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Ciudad",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      placeholder: "Distrito",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      value: "email",
    },
    {
      text: "País",
      align: "center",
      sortable: false,
      value: "country",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    { header: "Correo electrónico", key: "contact_email", width: 20, type: "email" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  saleImportData: [
    { header: "Nombres*", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Dirección", key: "address", width: 20, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra*", key: "total_paid", width: 20, type: "number" },
    { header: "Estado del pago* (PAGO EXITOSO/PROCESANDO PAGO)", key: "paid_status", width: 20, type: "string" },
    { header: "Método de pago", key: "payment_method", width: 20, type: "string" },
    { header: "Vendedor", key: "seller", width: 20, type: "string" },
    { header: "Fecha de venta (dd/mm/yyyy)", key: "sales_date", width: 20, type: "date" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30, show_on_detail: true },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30, show_on_detail: true },
    { header: "Correo electrónico", key: "email", type: "email", width: 30, show_on_detail: true },
    { header: "Teléfono", key: "phone", type: "string", width: 20, show_on_detail: true },
    { header: "Dirección", key: "address", type: "string", width: 20, show_on_detail: true },
    { header: "Producto/Cantidad/Precio", key: "products", type: "products", width: 30},
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    { header: "Método de pago", key: "payment_method", type: "string", width: 20 },
    { header: "Encargado", key: "created_uid", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
  ],
  orderFilters: {
    state: { id: "state", name: "Estado", primary: true },
    "products.id": { id: "products.id", name: "Producto", primary: true },
  },
  ticketFilters: {
    state: { id: "state", name: "Estado", primary: true },
    origin: { id: "origin", name: "Origen", primary: true },
    agent_id: { id: "agent_id", name: "Agente", primary: true, readonly_advisor: true, item_text: "full_name" },
  },
  tableTicket:  [
    { text: "Contacto", align: "center", sortable: false, value: "full_name" },
    { text: "Estado", align: "center", sortable: false, value: "state" },
    { text: "Registrado", align: "center", sortable: false, value: "created_at" },
    { text: "Origen", align: "center", sortable: false, value: "origin" },
    { text: "Encargado", align: "center", sortable: false, value: "agent_id" },
    { text: "Operación", align: "right", sortable: false, value: "operations" },
  ],
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      placeholder: "Seleccione",
      items: ["DNI", "Carnet de extranjería"],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          !v || (!!v && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      placeholder: "Distrito",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    hijos: {
      type: "text",
      label: "Hijos",
      placeholder: "Hijos",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    club: {
      type: "text",
      label: "Club",
      placeholder: "Club",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    company: {
      type: "text",
      label: "Empresa",
      placeholder: "Empresa",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    position: {
      type: "text",
      label: "Puesto",
      placeholder: "Puesto",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    carro_electrico: {
      type: "text",
      label: "Carro electrónico",
      placeholder: "Carro electrónico",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    marca_bolsas: {
      type: "text",
      label: "Marca bolsas",
      placeholder: "Marca bolsas",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    marca_palos: {
      type: "text",
      label: "Marca palos",
      placeholder: "Marca palos",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    marca_zapatos: {
      type: "text",
      label: "Marca zapatos",
      placeholder: "Marca zapatos",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    marca_gorras: {
      type: "text",
      label: "Marca gorras",
      placeholder: "Marca gorras",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    marca_maleta: {
      type: "text",
      label: "Marca maleta",
      placeholder: "Marca maleta",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    marca_guante: {
      type: "text",
      label: "Marca guante",
      placeholder: "Marca guante",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    carro_de_jalar: {
      type: "text",
      label: "Carro de jalar",
      placeholder: "Carro de jalar",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    medium_contact: {
      type: "select",
      label: "Medio de contacto",
      items: ["Presencial", "Whatsapp", "Facebook", "Instagram", "Llamada", "Correo", "Consulta", "Web", "Evento"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
    },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      data_type: "array",
      flex: { xs: 12 },
    },
  },
  ticketImportData: [
    { header: "Nombre del Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres*", key: "name", width: 30, type: "string", validate: true, required: true },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Tipo de Documento", key: "document_type", width: 20, type: "string" },
    { header: "Número de Documento", key: "document_number", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Hijos", key: "hijos", width: 20, type: "string" },
    { header: "Club", key: "club", width: 20, type: "string" },
    { header: "Empresa", key: "company", width: 20, type: "string" },
    { header: "Puesto", key: "position", width: 20, type: "string" },
    { header: "Carro electrico", key: "carro_electrico", width: 20, type: "string" },
    { header: "Marca bolsas", key: "marca_bolsas", width: 20, type: "string" },
    { header: "Marca palos", key: "marca_palos", width: 20, type: "string" },
    { header: "Marca zapatos", key: "marca_zapatos", width: 20, type: "string" },
    { header: "Marca gorras", key: "marca_gorras", width: 20, type: "string" },
    { header: "Marca maleta", key: "marca_maleta", width: 20, type: "string" },
    { header: "Marca guante", key: "marca_guante", width: 20, type: "string" },
    { header: "Carro de jalar", key: "carro_de_jalar", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent", validate: true },
    { header: "Origen*", key: "origin", width: 20, type: "string", validate: true, required: true },
  ],
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Tipo de Documento", key: "document_type", width: 20, type: "string" },
    { header: "Número de Documento", key: "document_number", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Hijos", key: "hijos", width: 20, type: "string" },
    { header: "Club", key: "club", width: 20, type: "string" },
    { header: "Empresa", key: "company", width: 20, type: "string" },
    { header: "Puesto", key: "position", width: 20, type: "string" },
    { header: "Carro electrico", key: "carro_electrico", width: 20, type: "string" },
    { header: "Marca bolsas", key: "marca_bolsas", width: 20, type: "string" },
    { header: "Marca palos", key: "marca_palos", width: 20, type: "string" },
    { header: "Marca zapatos", key: "marca_zapatos", width: 20, type: "string" },
    { header: "Marca gorras", key: "marca_gorras", width: 20, type: "string" },
    { header: "Marca maleta", key: "marca_maleta", width: 20, type: "string" },
    { header: "Marca guante", key: "marca_guante", width: 20, type: "string" },
    { header: "Carro de jalar", key: "carro_de_jalar", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Estado", key: "state", type: "state", width: 20 },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "Términos y condiciones (si/no)", key: "terms", width: 20, type: "boolean" },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, type: "string" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  agentTable:  [
    {
      text: "Encargado",
      align: "left",
      value: "full_name",
    },
    {
      text: "Correo electrónico",
      align: "center",
      value: "email",
    },
    {
      text: "Rol",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "Equipo",
      align: "center",
      value: "team",
    },
    {
      text: "Proyectos",
      align: "center",
      value: "projects",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "active",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        primary: "#492cdd",
        secondary:"#30e5c3",
        accent: "#82b1ff",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        background:  "#f6f7f9",
      },
      // light: {
      //   primary: "#1a9dde",
      //   // secondary: "#e158a0",
      //   secondary: "#ffc107",
      //   accent: "#607d8b",
      //   error: "#FF5252",
      //   info: "#2196F3",
      //   success: "#4CAF50",
      //   warning: "#FB8C00"
      // },
      dark: {
        // primary: "#607d8b",
        primary: "#bb86fc",
        // secondary: "#424242",
        secondary: "#30e5c3",
        accent: "#82b1ff",
        error: "#cf6679",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        // background:"#292930",
        background:"#121212",
        surface:"#ffffff",
      }
    }
  }
 };
 
 